/* eslint-disable no-nested-ternary */
import { Box, Button, Card, Container, Grid, Modal, Stack, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';

// API HOOKS & CUSTOM HOOK
import {
  useGetAttendanceReportsQuery,
  useGetForgetCheckOutQuery,
  useUpdateCheckOutMutation,
  useUpdateSubmitReportMutation,
} from 'services/private/users';
import useHandleApiResponse from 'customHooks/useHandleApiResponse';

// STYLES
import { fileViewModalStyles, formModalStyles } from 'styles/mui/common/modal-styles';

// COMPONENTS & UTILS
import ModalHeader from 'containers/common/components/ModalHeader';
import { primary } from 'styles/common/colors';
import SubmitBtn from 'containers/common/components/SubmitBtn';
import { Add } from '@mui/icons-material';
import moment from 'moment';
import ConfirmationDialog from 'shared/components/ConfirmationDialog';
import DailyReportsTable from './components/DailyReportsTable';
import DailyReportForm from './components/DailyReportForm';
import Analysis from './components/Analysis';
import ReportRequestForm from './components/ReportRequestForm';
import EditBreakForm from '../dashboard/hr/components/attendance/EditBreakForm';

function DailyReports() {
  const navigate = useNavigate();
  const location = useLocation();

  const { state: attendanceData } = location;
  const {
    data: checkInData,
    isLoading: attendanceLoading,
    isFetching,
  } = useGetAttendanceReportsQuery({ attendanceId: attendanceData?.id });
  const [updateSubmitReport, { error, isSuccess, isLoading }] = useUpdateSubmitReportMutation();
  const [updateCheckOut, { error: checkOutError, isSuccess: checkOutSuccess }] = useUpdateCheckOutMutation();
  useHandleApiResponse(checkOutError, checkOutSuccess, 'Checked Out Successfully!');
  const { data: ForgetCheckOut } = useGetForgetCheckOutQuery();
  useHandleApiResponse(error, isSuccess, 'Daily Report Submitted Successfully!');
  const [dailyReportFormOpen, setDailyReportFormOpen] = useState(false);
  const [requestFormOpen, setRequestFormOpen] = useState(false);
  const [editBreakFormOpen, setEditBreakFormOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const toggleDailyReportFormOpen = () => setDailyReportFormOpen(state => !state);
  const toggleRequestFormOpen = () => setRequestFormOpen(state => !state);
  const toggleEditBreakFormOpen = () => setEditBreakFormOpen(state => !state);

  const handleSubmitReport = async () => {
    const res = await updateSubmitReport({ is_report_submitted: true, id: checkInData?.id });
    setDeleteModalOpen(false);
    if (!res?.error && attendanceData?.check_out) {
      const timeFormated = moment().format('HH:mm:ss');
      const dateFormated = moment().format('yyyy-MM-DD');
      await updateCheckOut({ date: dateFormated, check_out_time: timeFormated, id: checkInData?.id });
      navigate('/portal');
    }
  };

  const breakData = useMemo(() => {
    const lastIndex = (checkInData?.break_time.length ?? 1) - 1;
    const latestData = checkInData?.break_time[lastIndex];
    return latestData;
  }, [checkInData?.break_time]);

  useEffect(() => {
    if (ForgetCheckOut && ForgetCheckOut?.report_submitted && !ForgetCheckOut?.check_out) {
      navigate('/portal');
    }
  }, [ForgetCheckOut, ForgetCheckOut?.check_out, ForgetCheckOut?.report_submitted]);

  useEffect(() => {
    if (breakData && !breakData?.end_break_time && !ForgetCheckOut?.report_submitted) {
      toggleEditBreakFormOpen();
    }
  }, [breakData, breakData?.end_break_time, ForgetCheckOut?.report_submitted]);

  return (
    <Container>
      <Box className="mb-4">
        <Typography variant="pageTitle">Submit Report</Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Card className="p-4">
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
              <Typography variant="h5" fontWeight={600}>
                What I have Done Today?
              </Typography>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Button
                  onClick={toggleDailyReportFormOpen}
                  variant="contained"
                  size="small"
                  startIcon={<Add />}
                  disabled={checkInData?.is_report_submitted}
                >
                  Add Report
                </Button>
              </Stack>
            </Stack>
            <Box>
              <DailyReportsTable data={checkInData} loading={attendanceLoading || isFetching} />
            </Box>
            <SubmitBtn
              size="large"
              isLoading={isLoading}
              onClick={() => setDeleteModalOpen(prevState => !prevState)}
              className="mt-4 w-100"
              label="Submit"
              isDisabled={!checkInData || checkInData?.is_report_submitted}
            />
          </Card>
        </Grid>

        {/* Add Daily Report Form */}
        <Modal open={dailyReportFormOpen} onClose={toggleDailyReportFormOpen}>
          <Box sx={{ ...fileViewModalStyles, width: '600px' }}>
            <ModalHeader title="Daily Task Report" onClose={toggleDailyReportFormOpen} />
            <DailyReportForm
              afterRequestSend={toggleDailyReportFormOpen}
              responseHandler={resp => {
                if (!resp.error) {
                  toggleDailyReportFormOpen();
                }
              }}
              attendanceID={checkInData?.id}
            />
          </Box>
        </Modal>

        {/* Create Request Report Form */}
        <Modal open={requestFormOpen} onClose={toggleRequestFormOpen}>
          <Box sx={formModalStyles}>
            <ModalHeader title="Task Report Request" onClose={toggleRequestFormOpen} />
            <ReportRequestForm
              reports={checkInData?.daily_report}
              afterRequestSend={toggleRequestFormOpen}
              responseHandler={resp => {
                if (!resp.error) {
                  toggleRequestFormOpen();
                }
              }}
              attendanceID={checkInData?.id}
            />
          </Box>
        </Modal>

        {/* Edit Break Form */}
        <Modal open={editBreakFormOpen} onClose={() => {}}>
          <Box sx={formModalStyles}>
            <ModalHeader title="End Your Break to Proceed" onClose={() => {}} />
            <EditBreakForm
              responseHandler={resp => {
                if (!resp.error) {
                  toggleEditBreakFormOpen();
                }
              }}
              breakData={breakData}
              breakForget
            />
          </Box>
        </Modal>

        <ConfirmationDialog
          open={isDeleteModalOpen}
          message="Are you sure you want to Submit the Daily Report?"
          onConfirm={handleSubmitReport}
          onClose={() => {
            setDeleteModalOpen(false);
          }}
        />

        <Grid item xs={12} md={4} className="d-flex flex-column gap-3">
          <Card sx={{ backgroundColor: primary }} className="p-4">
            <Typography color="white" fontWeight={600} variant="h6">
              {'Today\'s Quote'}
            </Typography>
            <Typography mt={2} color="white" variant="body2">
              “Embrace your uniqueness, and let your true self shine.”{' '}
            </Typography>
          </Card>

          <Card className="p-4">
            <Typography variant="h6" fontWeight={600}>
              {'Today\'s Time Analysis'}
            </Typography>
            <Analysis
              productiveHours={
                checkInData && !checkInData?.is_report_submitted ? checkInData?.productive_hours : '0h 0m'
              }
              presentHours={
                checkInData && !checkInData?.is_report_submitted ? checkInData?.total_hours : '0h 0m'
              }
            />
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

export default DailyReports;
