import React from 'react';
import PropTypes from 'prop-types';
import { ThreeDots } from 'react-loader-spinner';
import { Box, useTheme } from '@mui/material';

function SectionLoader({ radius, height }) {
  const theme = useTheme();
  const colors = theme.palette;
  const primary = colors.primary.main;

  return (
    <Box className="d-flex justify-content-center align-items-center" sx={{ height }}>
      <ThreeDots height={80} width={80} radius={radius} color={primary} visible />
    </Box>
  );
}

SectionLoader.propTypes = {
  height: PropTypes.string,
  radius: PropTypes.number,
};

SectionLoader.defaultProps = {
  height: '40vh',
  radius: 9,
};

export default SectionLoader;
