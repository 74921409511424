import { configureStore } from '@reduxjs/toolkit';
import { serviceMiddlewares, serviceReducers } from 'services';
import authSlice from './slices/authSlice';
import brandingSlice from './slices/brandingSlice';
import themeSlice from './slices/themeSlice';

const store = configureStore({
  reducer: {
    auth: authSlice,
    branding: brandingSlice,
    theme: themeSlice,
    ...serviceReducers,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(serviceMiddlewares),
});

export default store;
