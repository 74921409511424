const dailyReportsTableHeadData = [
  {
    label: 'Project',
    id: 'project',
  },
  {
    label: 'Productive Hours',
    id: 'productive_hours',
  },
  {
    label: 'Minutes',
    id: 'mins',
  },
  {
    label: 'Actions',
    id: 'actions',
  },
];

export default dailyReportsTableHeadData;
