import * as yup from 'yup';
import { yupLowercaseValidator, yupDateValidator } from 'shared/helpers/utilityFunctions';

export const PERSONAL_INFO_INIT_VALS = {
  employee_name: '',
  gender: '',
  dob: '',
  age: '',
  nationality: '',
  marital_status: '',
  national_id: '',
  personal_mob: '',
  personal_email: '',
  address: '',
  account_number: '',
  account_name: '',
  bank_name: '',
  bank_address: '',
  iban: '',
  currency: '',
};

export const PERSONAL_INFO_SCHEMA = yup.object({
  employee_name: yup
    .string()
    .matches(/^[a-zA-Z ]*$/, 'Only Alphabets are allowed!')
    .required('Required'),
  gender: yup.string().required('Required').nullable(),
  dob: yup.string().required('Required').nullable(),
  age: yup.number().min(18, 'Must be atleast 18').required('Required'),
  marital_status: yup.string().required('Required').nullable(),
  national_id: yup.string().max(25, 'Maximum 25 characters allowed').required('Required').nullable(),
  personal_mob: yup.string().max(20, 'Maximum 20 characters allowed').required('Required').nullable(),
  personal_email: yup
    .string()
    .email()
    .test('lowercase', 'Should be lowercase!', yupLowercaseValidator)
    .required('Required')
    .nullable(),
  address: yup.string().max(255, 'Cannot be more than 250 characters').required('Required').nullable(),
  account_number: yup.string().nullable(),
  account_name: yup.string().when('account_number', {
    is: value => value?.length > 0,
    then: yup.string().required('Required!').nullable(),
    otherwise: yup.string().nullable(),
  }),
  bank_name: yup.string().when('account_number', {
    is: value => value?.length > 0,
    then: yup.string().required('Required!').nullable(),
    otherwise: yup.string().nullable(),
  }),
  bank_address: yup
    .string()
    .max(255, 'Cannot be more than 255 characters!')
    .when('account_number', {
      is: value => value?.length > 0,
      then: yup.string().required('Required!').nullable(),
      otherwise: yup.string().nullable(),
    }),
  iban: yup.string().when('account_number', {
    is: value => value?.length > 0,
    then: yup.string().required('Required!').nullable(),
    otherwise: yup.string().nullable(),
  }),
  currency: yup.string().when('account_number', {
    is: value => value?.length > 0,
    then: yup.string().required('Required!').nullable(),
    otherwise: yup.string().nullable(),
  }),
});

export const EDUCATION_FIELD_ARRAY_DEF_VAL = {
  name: '',
  start_date: null,
  end_date: null,
  is_complete: false,
};

export const EXPERIENCE_FIELD_ARRAY_DEF_VAL = {
  name: '',
  start_date: null,
  end_date: null,
  is_complete: false,
};

export const EXPERIENCE_AND_EDUCATION_INIT_VALS = {
  education: [EDUCATION_FIELD_ARRAY_DEF_VAL],
  experience: [EXPERIENCE_FIELD_ARRAY_DEF_VAL],
};

export const EXPERIENCE_AND_EDUCATION_SCHEMA = yup.object({
  education: yup.array().of(
    yup.object({
      name: yup.string().required('Required'),
      start_date: yup.date().typeError('Invalid date!').required('Required').nullable(),
      is_complete: yup.boolean().required('Required'),
      end_date: yup.date().when('is_complete', {
        is: val => val === true,
        then: yup.date().nullable(),
        otherwise: yup
          .date()
          .typeError('Invalid date!')
          .required('Required')
          .min(yup.ref('start_date'), 'End date must be later than start date')
          .nullable(),
      }),
    })
  ),
  experience: yup.array().of(
    yup.object({
      name: yup.string().required('Required'),
      start_date: yup.date().typeError('Invalid date!').required('Required').nullable(),
      is_complete: yup.boolean().required('Required'),
      end_date: yup.date().when('is_complete', {
        is: val => val === true,
        then: yup.date().nullable(),
        otherwise: yup
          .date()
          .typeError('Invalid date!')
          .min(yup.ref('start_date'), 'End date must be later than start date')
          .test('date', 'Invalid date!', yupDateValidator)
          .required('Required')
          .nullable(),
      }),
    })
  ),
});
