import React from 'react';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import PropTypes from 'prop-types';

function FileViewer({ fileUrls, config }) {
  return (
    <DocViewer
      prefetchMethod="GET"
      config={{
        header: {
          disableHeader: true,
          disableFileName: true,
        },
        csvDelimiter: ',', // "," as default,
        pdfZoom: {
          defaultZoom: 1.1, // 1 as default,
          zoomJump: 0.2, // 0.1 as default,
        },
        pdfVerticalScrollByDefault: false, // false as default
        ...config,
      }}
      documents={fileUrls}
      pluginRenderers={DocViewerRenderers}
    />
  );
}

FileViewer.defaultProps = {
  fileUrls: [],
  config: {},
};

FileViewer.propTypes = {
  fileUrls: PropTypes.arrayOf(
    PropTypes.shape({
      uri: PropTypes.string,
      fileData: PropTypes.string, // string or ArrayBuffer
      fileName: PropTypes.string,
      fileType: PropTypes.string,
    })
  ),
  config: PropTypes.object,
};

export default FileViewer;
