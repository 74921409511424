import * as yup from 'yup';

export const dailyReportFormInitialValue = {
  project: '',
  productive_hours: 0,
  mins: 0,
  report: '',
  checked_in_date: '',
};

export const ReportRequestFormInitialValue = {
  reason: '',
};

export const dailyReportFormInitialSchema = yup.object({
  project: yup.string().required('Required!'),
  productive_hours: yup.number().integer().max(16, 'Must be less than or equal to 16'),
  mins: yup.number().integer().max(59, 'Must be less than 60').required('Required!'),
  report: yup.string().required('Required!'),
  checked_in_date: yup.string().required('Required!'),
});

export const ReportRequestFormInitialSchema = yup.object({
  reason: yup.string().max(255, 'Maximum 255 characters allowed').required('Required!'),
});
