import * as DOMPurify from 'dompurify';
import moment from 'moment';
import { EMPLOYEE_STATUS_OPTIONS } from 'shared/utils/data';
import { STORAGE_URL } from 'utilities/constants';

export const isBadRequest = error => error !== undefined && 'data' in error && error?.status === 400;

export const isUnAuthorized = error => error !== undefined && 'data' in error && error?.status === 403;

export const getUserWorkingStatusColor = (value = 'idle') => {
  const option = EMPLOYEE_STATUS_OPTIONS.find(item => String(item.value) === String(value));

  if (!option) {
    // return gray
    return '#989a96';
  }

  if (option.value === 'idle') {
    return '#efc617';
  }
  if (option.value === 'working') {
    return '#57aad8';
  }
  if (option.value === 'research_and_development') {
    return '#66d857';
  }
  // return gray
  return '#989a96';
};

export const phoneNumberValidator = input => {
  const value = String(input);

  if (value === '0') return true;

  if (!value) return true;

  if (value.match(/^[0-9,+,-]+$/)) return true;

  return false;
};

export const numberValidator = input => {
  const value = String(input);

  if (!value) return true;

  if (value.match(/^[0-9]+$/)) return true;

  return false;
};

export const excludeObjectKeys = (targetObject = {}, keysToExclude = []) => {
  const modified = { ...targetObject };

  keysToExclude.forEach(key => {
    delete modified[key];
  });

  return modified;
};

export const sanitizeHTML = html => DOMPurify.sanitize(html);

export const getFileNameFromS3Url = (s3Url, trim = false) => {
  const limit = 17;
  if (!s3Url) return null;

  if (trim) {
    if (s3Url?.replace(STORAGE_URL, '')?.length <= limit) {
      return s3Url?.replace(STORAGE_URL, '');
    }

    return s3Url?.replace(STORAGE_URL, '')?.slice(0, limit)?.concat('...');
  }
  return s3Url?.replace(STORAGE_URL, '');
};

export const imageFileTypeValidator = (fileType = '') => {
  const regex = /(gif|jpe?g|tiff?|png|webp|bmp|ico)$/i;

  const isValid = regex.test(fileType);

  if (isValid) return { isValid, errorMessage: null };

  return { isValid, errorMessage: 'Please choose a valid image!' };
};

export const documentFileTypeValidator = (fileType = '') => {
  const isValid =
    fileType.includes('officedocument') || fileType.includes('pdf') || fileType.includes('text');

  if (isValid) return { isValid, errorMessage: null };

  return { isValid, errorMessage: 'Only MS office files are allowed!' };
};

export function yupDateValidator(value) {
  const format = 'YYYY-MM-DD';

  if (!value || value === 'Invalid date') {
    return false;
  }

  const isValid = moment(value, format, true).isValid();

  if (!isValid) {
    return false;
  }

  return true;
}

export function yupTimeValidator(value) {
  const format = 'HH:mm:ss';

  if (!value) {
    return false;
  }

  const isValid = moment(value, format, true).isValid();

  if (!isValid) {
    return false;
  }

  return true;
}

export function yupLessTimeValidator(value) {
  const format = 'HH:mm:ss';

  if (!value) {
    return false;
  }
  const currentTime = moment().format('HH:mm:ss');
  const isValid = moment(value, format).isBefore(moment(currentTime, format));
  if (!isValid) {
    return false;
  }

  return true;
}

export function yupLowercaseValidator(value) {
  const containsUppercase = /[A-Z]/.test(value);
  return !containsUppercase;
}

export const getFirstDayOfMonth = (momentInstance, format = 'YYYY-MM-DD') => {
  const firstDay = momentInstance.date(1);

  return firstDay.format(format);
};

export const getLastDayOfMonth = (momentInstance, format = 'YYYY-MM-DD') => {
  const days = momentInstance.daysInMonth();

  return momentInstance.date(days).format(format);
};
