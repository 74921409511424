import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';

// CONTEXT
import AddInfoContextProvider from 'containers/portal/onboard/add-info/context/AddInfoContext';
import RequestsContextProvider from 'containers/portal/requests/context/RequestsContext';
import { JobContextProvider } from 'containers/portal/hr/Jobs/context/JobContext';

// COMPONENTS
import GlobalLoader from 'containers/common/loaders/GlobalLoader';
import DailyReports from 'containers/portal/dailyReports';
import ScrollToTop from './ScrollToTop';

// ROUTES GUARDS
const PrivateRouteGuard = lazy(() => import('./PrivateRouteGuard'));
const ForgetCheckoutRoute = lazy(() => import('./ForgetCheckoutRoute'));
const PrivateRoutes = lazy(() => import('./PrivateRoutes'));
const PublicRoutes = lazy(() => import('./PublicRoutes'));
const OnboardRoutes = lazy(() => import('./OnboardRoutes'));

// COMMON
const LayoutWrapper = lazy(() => import('containers/common/layout'));
const AdminAccessWrapper = lazy(() => import('containers/common/components/AdminAccessWrapper'));
const InterviewAccessWrapper = lazy(() => import('containers/common/components/InterviewAccessWrapper'));

// AUTH ROUTES & MISCELLANEOUS ROUTES
const Login = lazy(() => import('containers/public-pages/login'));
const ForgotPassword = lazy(() => import('containers/public-pages/forgot-password'));
const ResetPassword = lazy(() => import('containers/public-pages/reset-password'));
const Unauthorized = lazy(() => import('containers/portal/misc/Unauthorized'));

// PUBLIC ROUTES
const Careers = lazy(() => import('containers/public-pages/careers'));
const CareerDetails = lazy(() => import('containers/public-pages/careers/career-details'));
const ApplyJob = lazy(() => import('containers/public-pages/careers/apply-job'));

// PORTAL ROUTES
const Dashboard = lazy(() => import('containers/portal/dashboard'));
const Engagements = lazy(() => import('containers/portal/engagements'));
const Todos = lazy(() => import('containers/portal/todos'));

// COMPANY
const CompanyProfiles = lazy(() => import('containers/portal/hr/company/profiles'));
const AddCompanyProfile = lazy(() => import('containers/portal/hr/company/profiles/components/AddCompanyProfile'));
const CompanyFiles = lazy(() => import('containers/portal/hr/company/files'));
const EditCompanyProfile = lazy(() => import('containers/portal/hr/company/profiles/components/EditCompanyProfile'));
const CompanyHolidays = lazy(() => import('containers/portal/hr/company/holidays'));

// USER
const UserProfile = lazy(() => import('containers/portal/user/profile'));
const Calendar = lazy(() => import('containers/portal/user/calendar'));
const Signature = lazy(() => import('containers/portal/user/signature'));

// PAYROLL
const Salary = lazy(() => import('containers/portal/hr/payroll/salary'));
const ViewSalaryDetails = lazy(() => import('containers/portal/hr/payroll/salary/Employee/components/ViewSalaryDetails'));
const FinalPay = lazy(() => import('containers/portal/hr/payroll/final-pay'));
const ViewFinalPayForm = lazy(() => import('containers/portal/hr/payroll/final-pay/components/ViewFinalPayForm'));

// EMPLOYEE
const Employees = lazy(() => import('containers/portal/hr/employees'));
const EmployeeProfile = lazy(() => import('containers/portal/hr/employee-profile'));
const AddEmployee = lazy(() => import('containers/portal/hr/add-employee'));

// STORAGE
const Storage = lazy(() => import('containers/portal/storage/main'));
const Trash = lazy(() => import('containers/portal/storage/trash'));

// SETTINGS
const Settings = lazy(() => import('containers/portal/settings'));
const LookupsSettings = lazy(() => import('containers/portal/settings/lookups'));
const LeavesSettings = lazy(() => import('containers/portal/settings/leaves'));
const ShiftsSettings = lazy(() => import('containers/portal/settings/shifts'));

// TEAM
const Teams = lazy(() => import('containers/portal/teams'));
const AddTeam = lazy(() => import('containers/portal/teams/add'));
const TeamDetail = lazy(() => import('containers/portal/teams/details'));
const EditTeam = lazy(() => import('containers/portal/teams/edit'));

// PROJECT
const Projects = lazy(() => import('containers/portal/projects'));
const AddProject = lazy(() => import('containers/portal/projects/add'));
const ProjectDetail = lazy(() => import('containers/portal/projects/details'));
const EditProject = lazy(() => import('containers/portal/projects/edit'));

// CLIENT
const ClientsAccessWrapper = lazy(() => import('containers/portal/client/components/ClientsAccessWrapper'));
const Clients = lazy(() => import('containers/portal/client'));
const AddClient = lazy(() => import('containers/portal/client/add'));
const ClientDetail = lazy(() => import('containers/portal/client/details'));
const EditClient = lazy(() => import('containers/portal/client/edit'));

// REQUEST
const Requests = lazy(() => import('containers/portal/requests'));
const DocumentRequests = lazy(() => import('containers/portal/requests/document-requests'));
const HalfDayRequests = lazy(() => import('containers/portal/requests/half-day-requests'));
const HolidayRequests = lazy(() => import('containers/portal/requests/holiday-requests'));
const LeaveRequests = lazy(() => import('containers/portal/requests/leave-requests'));
const WorkFromHomeRequests = lazy(() => import('containers/portal/requests/work-from-home-requests'));
const ResourceRequired = lazy(() => import('containers/portal/requests/resource-required'));

// JOBS
const Jobs = lazy(() => import('containers/portal/hr/Jobs'));
const AddJob = lazy(() => import('containers/portal/hr/Jobs/AddJob'));
const JobDetail = lazy(() => import('containers/portal/hr/Jobs/JobDetail'));
const EditJob = lazy(() => import('containers/portal/hr/Jobs/EditJob'));
const Interviews = lazy(() => import('containers/interviews'));

// HELP & SUPPORT
const HelpAndSupport = lazy(() => import('containers/portal/helpAndSupport'));
const TicketDetails = lazy(() => import('containers/portal/helpAndSupport/components/TicketDetails'));

// ATTENDANCE
const MyReports = lazy(() => import('containers/portal/attendance'));
const TimeSummary = lazy(() => import('containers/portal/attendance/components/timeSummary/TimeSummary'));
const ProductivityReport = lazy(() => import('containers/portal/attendance/components/productivityReport/ProductivityReport'));
const KpiTagsReport = lazy(() => import('containers/portal/attendance/components/kpiReport/KpiTagsReport'));
const EmployeeAttendance = lazy(() => import('containers/portal/attendance/components/employeeAttendance/EmployeeAttendance'));
const ReportRequest = lazy(() => import('containers/portal/attendance/components/productivityReport/ReportRequest'));

// ASSETS
const AssetsLogs = lazy(() => import('containers/portal/assets-management/assets-logs'));
const AddAssets = lazy(() => import('containers/portal/assets-management/assets-logs/add'));
const EditAssets = lazy(() => import('containers/portal/assets-management/assets-logs/edit'));
const AssetDetail = lazy(() => import('containers/portal/assets-management/assets-logs/details'));
const FixedAssets = lazy(() => import('containers/portal/assets-management/fixed-assets'));
const AddFixedAsset = lazy(() => import('containers/portal/assets-management/fixed-assets/add'));
const EditFixedAsset = lazy(() => import('containers/portal/assets-management/fixed-assets/edit'));

// GUEST ROUTES
const OfferLetter = lazy(() => import('containers/guest-pages/offer-letter'));

// ONBOARDING
const OnboardingQueue = lazy(() => import('containers/portal/hr/onboarding/onboarding-queue'));
const OnboardingQueueDetail = lazy(() => import('containers/portal/hr/onboarding/onboarding-queue/queue-detail'));
const DirectOnboard = lazy(() => import('containers/portal/hr/onboarding/direct-onboard'));
const OnboardEmployee = lazy(() => import('containers/portal/onboard/employee'));
const AddInfo = lazy(() => import('containers/portal/onboard/add-info'));

// HIRE PACKET
const ViewHirePacket = lazy(() => import('containers/portal/hr/hire-packet/view-hire-packet'));
const CreateNewHirePacket = lazy(() => import('containers/portal/hr/hire-packet'));

// CUSTOMERS
const Customers = lazy(() => import('containers/portal/hr/customers'));
const AddCustomer = lazy(() => import('containers/portal/hr/customers/add'));
const EditCustomer = lazy(() => import('containers/portal/hr/customers/edit'));

// POLICIES
const Policies = lazy(() => import('containers/portal/policies'));
const AddPolicies = lazy(() => import('containers/portal/policies/add'));
const EditPolicies = lazy(() => import('containers/portal/policies/edit'));
const PoliciesDetail = lazy(() => import('containers/portal/policies/detail'));

// DEMO REQUEST
const DemoRequest = lazy(() => import('containers/portal/hr/demo-request'));
const RequestDemo = lazy(() => import('containers/public-pages/request-demo'));

function AppRoutes() {
  return (
    <Suspense fallback={<GlobalLoader />}>
      <BrowserRouter>
        <ScrollToTop />

        <Routes>
          <Route path="/" element={<LayoutWrapper />}>
            {/* AUTH ROUTES */}
            <Route path="auth" element={<PublicRoutes />}>
              <Route path="login" element={<Login />} />
              <Route path="forgot-password" element={<ForgotPassword />} />
              <Route path="reset-password" element={<ResetPassword />} />
            </Route>

            {/* PORTAL ROUTES */}

            <Route path="/portal" element={<PrivateRoutes />}>
              <Route path="/portal" element={<PrivateRouteGuard />}>
                <Route index element={<Dashboard />} />
                <Route path="pages" element={<ForgetCheckoutRoute />}>
                  <Route element={<Outlet />}>
                    <Route path="user" element={<Outlet />}>
                      <Route path="profile" element={<UserProfile />} />
                      <Route path="calendar" element={<Calendar />} />
                      <Route path="signature" element={<Signature />} />
                    </Route>

                    <Route path="engagements" element={<Engagements />} />

                    <Route path="hr" element={<Outlet />}>
                      <Route path="company" element={<Outlet />}>
                        <Route path="profiles" element={<CompanyProfiles />} />
                        <Route path="profiles/add" element={<AddCompanyProfile />} />
                        <Route path="profiles/edit" element={<EditCompanyProfile />} />
                        <Route path="files" element={<CompanyFiles />} />
                        <Route path="holidays" element={<CompanyHolidays />} />
                      </Route>

                      <Route path="payroll" element={<Outlet />}>
                        <Route path="salaries" element={<Outlet />}>
                          <Route index element={<Salary />} />

                          <Route path="details" element={<ViewSalaryDetails />} />
                        </Route>

                        <Route path="final-pay" element={<Outlet />}>
                          <Route index element={<FinalPay />} />
                          <Route path=":userId" element={<ViewFinalPayForm />} />
                        </Route>
                      </Route>

                      <Route path="employees" element={<Outlet />}>
                        <Route index element={<Employees />} />
                        <Route path=":userId/profile" element={<EmployeeProfile />} />
                        <Route path="add" element={<AddEmployee />} />
                      </Route>

                      <Route path="demo-request" element={<Outlet />}>
                        <Route index element={<DemoRequest />} />
                      </Route>

                      <Route path="clients" element={<ClientsAccessWrapper />}>
                        <Route index element={<Clients />} />
                        <Route path="add" element={<AddClient />} />
                        <Route path=":id/detail" element={<ClientDetail />} />
                        <Route path=":id/edit" element={<EditClient />} />
                      </Route>

                      <Route path="customers" element={<Outlet />}>
                        <Route index element={<Customers />} />
                        <Route path="add" element={<AddCustomer />} />
                        <Route path="edit/:id" element={<EditCustomer />} />
                      </Route>

                      {/* SCREENING */}
                      <Route path="screening" element={<Outlet />}>
                        <Route
                          path="jobs"
                          element={(
                            <JobContextProvider>
                              <AdminAccessWrapper />
                            </JobContextProvider>
                          )}
                        >
                          <Route index element={<Jobs />} />
                          <Route path="add" element={<AddJob />} />
                          <Route path=":id/detail" element={<JobDetail />} />
                          <Route path=":id/edit" element={<EditJob />} />
                        </Route>

                        <Route
                          path="interviews"
                          element={(
                            <InterviewAccessWrapper>
                              {' '}
                              <Interviews />{' '}
                            </InterviewAccessWrapper>
                          )}
                        />
                      </Route>

                      <Route path="hire-packet" element={<AdminAccessWrapper />}>
                        <Route path="create/:id" element={<CreateNewHirePacket />} />
                        <Route path="view/:id" element={<ViewHirePacket />} />
                      </Route>

                      {/* ONBOARDING */}
                      <Route path="onboarding" element={<AdminAccessWrapper />}>
                        <Route index element={<OnboardingQueue />} />
                        <Route path="detail/:id" element={<OnboardingQueueDetail />} />
                        <Route path="direct-onboard" element={<DirectOnboard />} />
                      </Route>
                    </Route>

                    {/* POLICIES  */}
                    <Route path="policies" element={<Outlet />}>
                      <Route index element={<Policies />} />
                      <Route path="add" element={<AddPolicies />} />
                      <Route path="edit/:id" element={<EditPolicies />} />
                      <Route path="detail/:id" element={<PoliciesDetail />} />
                    </Route>

                    <Route path="teams" element={<Outlet />}>
                      <Route index element={<Teams />} />
                      <Route path="add" element={<AddTeam />} />
                      <Route path=":id/detail" element={<TeamDetail />} />
                      <Route path=":id/edit" element={<EditTeam />} />
                    </Route>

                    <Route path="requests" element={<Outlet />}>
                      <Route
                        index
                        element={(
                          <RequestsContextProvider>
                            {' '}
                            <Requests />{' '}
                          </RequestsContextProvider>
                        )}
                      />
                      <Route
                        path="leave-requests"
                        element={(
                          <RequestsContextProvider>
                            {' '}
                            <LeaveRequests />{' '}
                          </RequestsContextProvider>
                        )}
                      />
                      <Route
                        path="work-from-home-requests"
                        element={(
                          <RequestsContextProvider>
                            {' '}
                            <WorkFromHomeRequests />{' '}
                          </RequestsContextProvider>
                        )}
                      />
                      <Route
                        path="half-day-requests"
                        element={(
                          <RequestsContextProvider>
                            {' '}
                            <HalfDayRequests />{' '}
                          </RequestsContextProvider>
                        )}
                      />
                      <Route
                        path="holiday-requests"
                        element={(
                          <RequestsContextProvider>
                            {' '}
                            <HolidayRequests />{' '}
                          </RequestsContextProvider>
                        )}
                      />
                      <Route path="document-requests" element={<DocumentRequests />} />
                      <Route path="resource-required" element={<ResourceRequired />} />
                    </Route>

                    <Route path="projects" element={<Outlet />}>
                      <Route index element={<Projects />} />
                      <Route path="add" element={<AddProject />} />
                      <Route path=":id/detail" element={<ProjectDetail />} />
                      <Route path=":id/edit" element={<EditProject />} />
                    </Route>

                    <Route path="assets-management" element={<Outlet />}>
                      <Route path="assets-logs" element={<Outlet />}>
                        <Route index element={<AssetsLogs />} />
                        <Route path="add" element={<AddAssets />} />
                        <Route path=":id/edit" element={<EditAssets />} />
                        <Route path=":id/detail" element={<AssetDetail />} />
                      </Route>

                      <Route path="fixed-assets" element={<Outlet />}>
                        <Route index element={<FixedAssets />} />
                        <Route path="add" element={<AddFixedAsset />} />
                        <Route path=":id/edit" element={<EditFixedAsset />} />
                      </Route>
                    </Route>

                    <Route path="my-attendance" element={<Outlet />}>
                      <Route index element={<MyReports />} />
                      <Route path="time-summary" element={<TimeSummary />} />
                      <Route path="productivity-report" element={<ProductivityReport />} />
                      {/* <Route path="kpi-adherence-report" element={<KpiReport />} /> */}
                      <Route path="kpi-adherence-report" element={<KpiTagsReport />} />
                      <Route path="employee-attendance" element={<EmployeeAttendance />} />
                      <Route path="report-request" element={<ReportRequest />} />
                    </Route>

                    <Route path="todos" element={<Todos />} />

                    <Route path="storage" element={<Outlet />}>
                      <Route path="trash" element={<Trash />} />
                      <Route path="main" element={<Storage />} />
                    </Route>

                    <Route path="settings" element={<Outlet />}>
                      <Route index element={<Settings />} />
                      <Route path="lookups" element={<LookupsSettings />} />
                      <Route path="leaves" element={<LeavesSettings />} />
                      <Route path="shifts" element={<ShiftsSettings />} />
                    </Route>

                    <Route path="help-and-support" element={<Outlet />}>
                      <Route path=":id" element={<TicketDetails />} />
                      <Route index element={<HelpAndSupport />} />
                    </Route>

                    <Route path="unauthorized" element={<Unauthorized />} />
                  </Route>
                </Route>
              </Route>
              <Route path="/portal/pages/daily-tasks-report" element={<DailyReports />} />
            </Route>

            {/* GUEST ROUTES */}
            <Route path="/guest" element={<Outlet />}>
              <Route path="offer-letter/:id" element={<OfferLetter />} />
            </Route>

            {/* ONBOARD */}
            <Route path="/onboard" element={<OnboardRoutes />}>
              <Route path="employee" element={<OnboardEmployee />} />
              <Route
                path="add-info"
                element={(
                  <AddInfoContextProvider>
                    <AddInfo />
                  </AddInfoContextProvider>
                )}
              />
            </Route>

            {/* PUBLIC ROUTES */}
            <Route index element={<Careers />} />

            <Route path="career" element={<Outlet />}>
              <Route path="details/:jobId" element={<CareerDetails />} />
              <Route path="apply/:jobId" element={<ApplyJob />} />

              <Route index element={<Careers />} />
            </Route>

            <Route path="request-demo" element={<RequestDemo />} />

            <Route path="*" element={<h1>Not Found!</h1>} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default AppRoutes;
