import {
  Box,
  IconButton,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import propTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { Delete, Edit } from '@mui/icons-material';

// API HOOKS & CUSTOM HOOKS
import {
  useDeleteDailyReportsMutation,
} from 'services/private/users';
import useHandleApiResponse from 'customHooks/useHandleApiResponse';

// COMPONENTS & UTILITIES
import SectionLoader from 'containers/common/loaders/SectionLoader';
import ConfirmationDialog from 'shared/components/ConfirmationDialog';
import { getComparator, stableSort } from 'utilities/helpers';
import ModalHeader from 'containers/common/components/ModalHeader';
import { fileViewModalStyles } from 'styles/mui/common/modal-styles';
import DailyReportsTableHead from './DailyReportsTableHead';
import EditDailyReportForm from './EditDailyReportForm';

function DailyReportsTable({ data, loading }) {
  const [deleteDailyReports, { error, isSuccess }] = useDeleteDailyReportsMutation();
  useHandleApiResponse(error, isSuccess, 'Daily Report deleted successfully!');
  const dailyReports = useMemo(() => data?.daily_report, [data?.daily_report]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('employee_name');
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selected, setSelected] = useState(null);

  // HANDLERS

  const handleSortRequest = property => {
    const isAsc = orderBy === property && order === 'asc';

    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const [editDailyReportFormOpen, setEditDailyReportFormOpen] = useState(false);
  const toggleEditDailyReportFormOpen = () => setEditDailyReportFormOpen(state => !state);

  const handleDeleteDailyReports = async () => {
    await deleteDailyReports(selected.id);
    setSelected(null);
    setDeleteModalOpen(false);
  };

  return (
    <Box>
      {!loading ? (
        <Box>
          <ConfirmationDialog
            open={isDeleteModalOpen}
            message="Are you sure you want to delete the Daily Report?"
            onConfirm={handleDeleteDailyReports}
            onClose={() => {
              setSelected(null);
              setDeleteModalOpen(false);
            }}
          />

          {/* Edit Daily Report Form */}
          <Modal open={editDailyReportFormOpen} onClose={toggleEditDailyReportFormOpen}>
            <Box sx={{ ...fileViewModalStyles, width: '600px' }}>
              <ModalHeader title="Edit Daily Task Report" onClose={toggleEditDailyReportFormOpen} />
              <EditDailyReportForm
                formData={selected}
                afterRequestSend={toggleEditDailyReportFormOpen}
                responseHandler={resp => {
                  if (!resp.error) {
                    toggleEditDailyReportFormOpen();
                  }
                }}
              />
            </Box>
          </Modal>

          {(dailyReports?.length > 0 && data && !data?.is_report_submitted) ? (
            <TableContainer>
              <Table>
                <DailyReportsTableHead order={order} orderBy={orderBy} onRequestSort={handleSortRequest} />

                <TableBody>
                  {stableSort(dailyReports, getComparator(order, orderBy))?.map(row => (
                    <TableRow key={row?.id}>
                      <TableCell>{row?.project_name}</TableCell>

                      <TableCell>{row?.productive_hours}</TableCell>

                      <TableCell>{row.mins}</TableCell>
                      <TableCell>
                        <Stack direction="row" gap={0.5}>
                          <IconButton
                            onClick={() => {
                              toggleEditDailyReportFormOpen();
                              setSelected(row);
                            }}
                            title="Edit"
                            size="small"
                            disabled={data?.is_report_submitted}
                          >
                            <Edit />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              setDeleteModalOpen(prevState => !prevState);
                              setSelected(row);
                            }}
                            title="Delete"
                            size="small"
                            disabled={data?.is_report_submitted}
                          >
                            <Delete />
                          </IconButton>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Box p={2} sx={{ height: '30vh' }} className="customFlexCenter">
              <Typography variant="body1">No Record Found!</Typography>
            </Box>
          )}
        </Box>
      ) : (
        <SectionLoader />
      )}
    </Box>
  );
}

DailyReportsTable.defaultProps = {
  data: {},
  loading: false,
};

DailyReportsTable.propTypes = {
  data: propTypes.object,
  loading: propTypes.bool,
};

export default DailyReportsTable;
