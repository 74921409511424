/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
  data: null,
};

const brandingSlice = createSlice({
  name: 'branding',
  initialState,
  reducers: {
    onBrandingDataLoaded: (state, { payload }) => {
      state.isLoading = false;

      state.data = payload;

      return state;
    },

    onClearBrandingData: state => {
      state.isLoading = true;

      state.data = null;
      return state;
    },
  },
});

export default brandingSlice.reducer;

export const { onBrandingDataLoaded, onClearBrandingData } = brandingSlice.actions;
