export const EMPLOYEE_STATUS_OPTIONS = [
  // don't change without consulting backend dev
  {
    label: 'Idle',
    value: 'idle',
  },
  {
    label: 'Working',
    value: 'working',
  },
  {
    label: 'R&D',
    value: 'research_and_development',
  },
];

export const leaveCountKeyLabels = [
  { value: 'total_leaves', label: 'Total Annual Leaves' },
  { value: 'total_casual_leaves', label: 'Remaining Casual Leaves' },
  { value: 'approved_casual_leaves', label: 'Approved Casual Leaves' },
  { value: 'total_sick_leaves', label: 'Remaining Sick Leaves' },
  { value: 'approved_sick_leaves', label: 'Approved Sick Leaves' },
  { value: 'emergency_leaves', label: 'Emergency Leaves' },
  { value: 'total_approved_half_leave', label: 'Approved Half Day' },
  { value: 'total_approved_w_f_h', label: 'Approved WFH' },
  { value: 'total_approved_w_o_h', label: 'Approved Work On Holiday' },
];
