const colors = {
  primary: '#004D7A',
  secondary: '#e7e2e2',
  dark: '#6A7383',
  darker: '#2A3248',
  muted: '#999999',
  border: '#ced4da',
  disabled: '#f2f4f7',
  primaryHover: '#003d61',
  success: '#6cbf6c',
  grey: '#a7a7a7'
};

export const { primary, muted, secondary, dark, border, disabled, grey, darker } = colors;

export default colors;
