import { privateAPi } from '.';

export const brandingApi = privateAPi.injectEndpoints({
  endpoints: build => ({
    getBrandingData: build.query({
      query: () => '/api/company-branding/company-info/',
      providesTags: ['getBrandingData'],
    }),

    getHomePageContent: build.query({
      query: () => '/api/company-branding/company-home-page/',
      providesTags: ['getHomePageContent'],
    }),
  }),
});

export const { useGetBrandingDataQuery, useLazyGetBrandingDataQuery, useGetHomePageContentQuery } =
  brandingApi;
