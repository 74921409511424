import { privateAPi } from '.';

export const lookupsApi = privateAPi.injectEndpoints({
  endpoints: build => ({
    getFilteredLookups: build.query({
      query: type => `/api/lookups/search/${type}`,
      providesTags: ['GetFilteredLookups'],
    }),

    searchLookups: build.mutation({
      query: type => ({ url: `/api/lookups/search/${type}`, method: 'GET' }),
      providesTags: ['SearchLookup'],
    }),

    getEmployeesAndJobTitleLookup: build.query({
      query: () => '/api/accounts/employee-name-and-job-title',
      providesTags: ['GetEmployeesAndJobTitleLookup'],
    }),

    getLookupByType: build.query({
      query: type => `/api/lookups/search/${type}`,
      providesTags: ['GetLookupByType'],
    }),

    getLookupById: build.query({
      query: id => `/api/lookups/${id}/`,
      providesTags: ['GetLookupById'],
    }),

    getAllLookups: build.query({
      query: params => ({
        url: '/api/lookups/',
        params: {
          lookup_type: params?.lookup_type || undefined,
        },
      }),
      providesTags: ['GetAllLookups'],
    }),

    deleteLookup: build.mutation({
      query: id => ({
        url: `/api/lookups/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        'GetAllLookups',
        'SearchLookup',
        'GetFilteredLookups',
        'GetLookupByType',
        'GetLookupById',
      ],
    }),

    addLookup: build.mutation({
      query: body => ({
        url: '/api/lookups/',
        method: 'POST',
        body,
      }),
      invalidatesTags: [
        'GetAllLookups',
        'SearchLookup',
        'GetFilteredLookups',
        'GetLookupByType',
        'GetLookupById',
      ],
    }),

    editLookup: build.mutation({
      query: body => ({
        url: `/api/lookups/${body.id}/`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [
        'GetAllLookups',
        'SearchLookup',
        'GetFilteredLookups',
        'GetLookupByType',
        'GetLookupById',
      ],
    }),

    getProjectsLookup: build.query({
      query: () => ({
        url: '/api/project/names/',
        method: 'GET',
      }),
    }),

    getAssignedProjectsLookup: build.query({
      query: () => ({
        url: '/api/attendance/projects/names/',
        method: 'GET',
      }),
    }),

    addLeaveSetting: build.mutation({
      query: body => ({
        url: '/api/leaves/leave_type/',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['getLeaveSettingsList', 'getLeaveSetting'],
    }),

    getLeaveSettingsList: build.query({
      query: () => ({
        url: '/api/leaves/leave_type/',
      }),
      providesTags: ['getLeaveSettingsList'],
    }),

    getLeaveSetting: build.query({
      query: id => ({
        url: `/api/leaves/leave_type/${id}/`,
      }),
      providesTags: ['getLeaveSetting'],
    }),

    updateLeaveSetting: build.mutation({
      query: body => ({
        url: `/api/leaves/leave_type/${body?.id}/`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['getLeaveSettingsList', 'getLeaveSetting'],
    }),

    deleteLeaveSetting: build.mutation({
      query: id => ({
        url: `/api/leaves/leave_type/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['getLeaveSettingsList', 'getLeaveSetting'],
    }),
    getShiftsSettingsList: build.query({
      query: () => ({
        url: '/api/attendance/employee-shift/',
      }),
      providesTags: ['GetShiftsSettingsList'],
    }),
    addShiftsSetting: build.mutation({
      query: body => ({
        url: '/api/attendance/employee-shift/',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['GetShiftsSettingsList', 'GetShiftsSetting'],
    }),
    getShiftsSetting: build.query({
      query: id => ({
        url: `/api/attendance/employee-shift/${id}/`,
      }),
      providesTags: ['GetShiftsSetting'],
    }),
    updateShiftsSetting: build.mutation({
      query: body => ({
        url: `/api/attendance/employee-shift/${body?.id}/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['GetShiftsSettingsList', 'GetShiftsSetting'],
    }),

    deleteShiftsSetting: build.mutation({
      query: id => ({
        url: `/api/attendance/employee-shift/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['GetShiftsSettingsList', 'GetShiftsSetting'],
    }),
  }),
});

export const {
  useGetFilteredLookupsQuery,
  useSearchLookupsMutation,
  useGetEmployeesAndJobTitleLookupQuery,
  useGetLookupByTypeQuery,
  useGetLookupByIdQuery,
  useLazyGetAllLookupsQuery,
  useDeleteLookupMutation,
  useAddLookupMutation,
  useEditLookupMutation,
  useGetProjectsLookupQuery,
  useGetAssignedProjectsLookupQuery,
  useGetAllLookupsQuery,
  useGetLeaveSettingsListQuery,
  useGetLeaveSettingQuery,
  useUpdateLeaveSettingMutation,
  useDeleteLeaveSettingMutation,
  useAddLeaveSettingMutation,
  useGetShiftsSettingsListQuery,
  useAddShiftsSettingMutation,
  useGetShiftsSettingQuery,
  useUpdateShiftsSettingMutation,
  useDeleteShiftsSettingMutation,
} = lookupsApi;
