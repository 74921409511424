/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import SubmitBtn from 'containers/common/components/SubmitBtn';
import { Form, Formik } from 'formik';
import propTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

// API & HOOKS
import { useAddDailyReportsMutation, useGetAttendanceDatesQuery } from 'services/private/users';
import useHandleApiResponse from 'customHooks/useHandleApiResponse';
import { useGetAssignedProjectsLookupQuery } from 'services/private/lookups';

// COMPONENTS & UTILS
import FormikField from 'shared/components/form/FormikField';
import FormikSelect from 'shared/components/form/FormikSelect';
import { defaultConfig } from 'shared/components/ckEditor/config';
import FormikTextEditor from 'shared/components/form/FormikTextEditor';
import { dailyReportFormInitialSchema, dailyReportFormInitialValue } from '../utilities/formUtils';

function DailyReportForm({ responseHandler, attendanceID, afterRequestSend }) {
  const [addDailyReport, { error, isSuccess }] = useAddDailyReportsMutation();
  const { data: projectsData } = useGetAssignedProjectsLookupQuery();
  const { data: attendanceDate } = useGetAttendanceDatesQuery();
  const [initValues, setInitValues] = useState(dailyReportFormInitialValue);
  useHandleApiResponse(error, isSuccess, 'Daily Report Added Successfully!');

  const modifiedOptions = useMemo(() => {
    if (projectsData) {
      const options = projectsData?.map(opt => ({
        label: opt?.project_name,
        value: opt?.id,
      }));
      return options;
    }
    return [];
  }, [projectsData]);

  const modifiedDate = useMemo(() => {
    if (attendanceDate) {
      const options = attendanceDate?.map(opt => ({
        label: opt?.date,
        value: opt?.id,
      }));
      return options;
    }

    return [];
  }, [attendanceDate]);

  useEffect(() => {
    setInitValues(prevValue => ({
      ...prevValue,
      checked_in_date: modifiedDate[0]?.value,
    }));
  }, [modifiedDate]);

  return (
    <Box>
      <Formik
        enableReinitialize
        initialValues={initValues}
        validationSchema={dailyReportFormInitialSchema}
        onSubmit={async (values, { resetForm }) => {
          const response = await addDailyReport({
            productive_hours: values.productive_hours || 0,
            mins: values.mins || 0,
            project: values.project,
            report: values.report,
            attendance: attendanceID,
          });
          responseHandler(response);
          if (!response.error) {
            resetForm();
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form className="d-flex align-items-start flex-column gap-3">
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Typography variant="label" className="required">
                  Checked In Date
                </Typography>
                <FormikSelect name="checked_in_date" placeholder="Select Date" options={modifiedDate} />
              </Grid>

              <Grid sx={{ zIndex: 50 }} item xs={12} md={6}>
                <Typography className="required" variant="label">
                  Project
                </Typography>
                <FormikSelect name="project" placeholder="Select Project" options={modifiedOptions} />
              </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={4}>
              <Grid item xs={12} md={6}>
                <Typography variant="label">Hours</Typography>
                <FormikField placeholder="Hours" type="number" name="productive_hours" />
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography className="required" variant="label">
                  Minutes
                </Typography>
                <FormikField placeholder="Minutes" type="number" name="mins" />
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                <Typography variant="label" className="required">
                  Report
                </Typography>
              </Grid>

              <Grid item xs={12} md={12}>
                <FormikTextEditor
                  name="report"
                  config={defaultConfig}
                  isRequired
                />
              </Grid>
            </Grid>

            <Stack direction="row" mt={2} spacing={1}>
              <SubmitBtn label="Submit" isLoading={isSubmitting} />

              <Button variant="contained" color="secondary" onClick={afterRequestSend}>
                Cancel
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

DailyReportForm.defaultProps = {
  afterRequestSend: () => null,
  responseHandler: () => {},
  attendanceID: null,
};

DailyReportForm.propTypes = {
  afterRequestSend: propTypes.func,
  responseHandler: propTypes.func,
  attendanceID: propTypes.number,
};

export default DailyReportForm;
