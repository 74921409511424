import React from 'react';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import propTypes from 'prop-types';

function ModalHeader({ title, onClose }) {
  return (
    <Box>
      <Box className="d-flex align-items-center justify-content-between mb-2">
        <Typography variant="h6">
          {title}
        </Typography>

        <IconButton onClick={onClose} className="p-1">
          <Close fontSize="small" />
        </IconButton>
      </Box>

      <Divider className="my-3" />
    </Box>
  );
}

ModalHeader.propTypes = {
  title: propTypes.string.isRequired,
  onClose: propTypes.func.isRequired,
};

export default ModalHeader;
