import { Box, Typography } from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

function Analysis({ productiveHours, presentHours }) {
  const formattedProductiveTime = useMemo(() => {
    if (productiveHours) {
      const remainingTime = moment.duration(presentHours, 'h').subtract(moment.duration(productiveHours, 'HH:mm:ss'));
      const productiveTime = {
        hours: remainingTime.hours(),
        mins: remainingTime.minutes(),
      };
      return productiveTime;
    }
    return { hours: 0, mins: 0 };
  }, [productiveHours, presentHours]);

  const formattedPresentTime = useMemo(() => {
    if (presentHours) {
      const productiveTime = {
        hours: moment.duration(presentHours, 'h').hours(),
        mins: moment.duration(presentHours, 'h').minutes(),
      };
      return productiveTime;
    }
    return { hours: 0, mins: 0 };
  }, [presentHours]);

  return (
    <Box mt={2}>
      <Typography
        fontWeight={600}
        variant="h5"
      >{`${formattedProductiveTime?.hours}h ${formattedProductiveTime?.mins}m`}
      </Typography>
      <Typography variant="body1">
        Remaining Productive Hours
      </Typography>
      <Typography
        mt={2}
        fontWeight={600}
        variant="h5"
      >{`${formattedPresentTime?.hours}h ${formattedPresentTime?.mins}m`}
      </Typography>
      <Typography variant="body1">
        Present Hours
      </Typography>
    </Box>
  );
}

Analysis.defaultProps = {
  productiveHours: '',
  presentHours: '',
};

Analysis.propTypes = {
  productiveHours: PropTypes.any,
  presentHours: PropTypes.any,
};

export default Analysis;
