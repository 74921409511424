/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import getCustomTheme from 'styles/mui/generalCustomTheme';

const initialState = {
  palette: getCustomTheme({}).palette,
};
const themeSlice = createSlice({
  initialState,
  name: 'themeSlice',
  reducers: {
    onPaletteLoaded: (state, { payload: muiPalette }) => {
      state.palette = muiPalette;
      return state;
    },
  },
});

export default themeSlice.reducer;

export const { onPaletteLoaded } = themeSlice.actions;
