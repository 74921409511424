import { useCallback, useState } from 'react';
import { createTheme, lighten } from '@mui/material';
import { useDispatch } from 'react-redux';
import { onPaletteLoaded } from 'store/slices/themeSlice';

// STYLES
import getCustomTheme from 'styles/mui/generalCustomTheme';
import { primary, secondary } from 'styles/common/colors';

function useBuildTheme() {
  const [theme, setTheme] = useState(null);

  const dispatch = useDispatch();

  const buildDefaultTheme = useCallback(() => {
    let createdTheme = createTheme(getCustomTheme({}));

    const serializablePalette = Object.fromEntries(
      Object.entries(createdTheme.palette).filter(([key]) => !(createdTheme.palette[key] instanceof Function))
    );

    // add extra light colors
    Object.entries(serializablePalette).forEach(([key, value]) => {
      if (value instanceof Object && value?.main) {
        serializablePalette[key] = { ...value, lighter: lighten(value.main, 0.7) };
      }
    });

    createdTheme = createTheme(createdTheme, {
      palette: serializablePalette,
    });

    dispatch(onPaletteLoaded(serializablePalette));
    setTheme(createdTheme);
  }, []);

  const buildTheme = useCallback(
    branding => {
      try {
        const themeValues = getCustomTheme({
          primaryClr: branding?.palette?.primary_color || primary,
          secondaryClr: branding?.palette.secondary_color || secondary,
        });

        let generatedTheme = createTheme(themeValues, {
          palette: {
            primary: {
              ...(branding?.palette?.primary_contrast_color
                ? { contrastText: branding?.palette?.primary_contrast_color }
                : {}),
            },
          },
        });

        const serializablePalette = Object.fromEntries(
          Object.entries(generatedTheme.palette).filter(
            ([key]) => !(generatedTheme.palette[key] instanceof Function)
          )
        );

        // add extra light colors
        Object.entries(serializablePalette).forEach(([key, value]) => {
          if (value instanceof Object && value?.main) {
            serializablePalette[key] = { ...value, lighter: lighten(value.main, 0.7) };
          }
        });

        generatedTheme = createTheme(generatedTheme, {
          palette: serializablePalette,
        });

        dispatch(onPaletteLoaded(serializablePalette));

        setTheme(generatedTheme);
      } catch (error) {
        if (error?.message) {
          buildDefaultTheme(branding);
        }
      }
    },
    [primary, secondary]
  );

  return { theme, buildTheme, buildDefaultTheme };
}

export default useBuildTheme;
