import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import React from 'react';
import propTypes from 'prop-types';

// UTILITIES
import dailyReportsTableHeadData from '../utilities/data';

function DailyReportsTableHead({ order, orderBy, onRequestSort }) {
  return (
    <TableHead>
      <TableRow>
        {dailyReportsTableHeadData?.map(cell => (
          <TableCell key={cell?.id} sortDirection={orderBy === cell?.id ? order : false}>
            <TableSortLabel
              direction={orderBy === cell?.id ? order : 'asc'}
              active={orderBy === cell?.id}
              onClick={() => onRequestSort(cell?.id)}
            >
              {cell?.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

DailyReportsTableHead.propTypes = {
  order: propTypes.string.isRequired,
  orderBy: propTypes.string.isRequired,
  onRequestSort: propTypes.func.isRequired,
};

export default DailyReportsTableHead;
