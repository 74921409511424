import React, { createContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Modal, Typography } from '@mui/material';

// components
import ModalHeader from 'containers/common/components/ModalHeader';
import FileViewer from 'shared/components/fileViewer';

// styles
import styles from 'styles/containers/portal/jobs.module.scss';

export const JobContext = createContext({
  selectedJobApplication: null,
  setSelectedJobApplication: () => {},
  openFileViewerModal: () => {},
  interviewHistoryOpen: false,
  toggleInterviewHistoryOpen: () => {},
});

export function JobContextProvider({ children }) {
  const [selectedJobApplication, setSelectedJobApplication] = useState(null);
  const [interviewHistoryOpen, setInterviewHistoryOpen] = useState(false);

  const [fileViewerState, setFileViewerState] = useState({
    open: false,
    uris: [],
    title: '',
  });

  const closeFileViewerModal = () => setFileViewerState(prev => ({ ...prev, open: false }));

  const openFileViewerModal = (title, uris) => setFileViewerState({ open: true, uris, title });

  const toggleInterviewHistoryOpen = () => setInterviewHistoryOpen(prev => !prev);

  const bundle = useMemo(
    () => ({
      selectedJobApplication,
      setSelectedJobApplication,
      openFileViewerModal,
      interviewHistoryOpen,
      toggleInterviewHistoryOpen,
    }),
    [
      selectedJobApplication,
      setSelectedJobApplication,
      openFileViewerModal,
      interviewHistoryOpen,
      toggleInterviewHistoryOpen,
    ]
  );

  return (
    <JobContext.Provider value={bundle}>
      {children}

      {/* Files viewer */}
      <Modal open={fileViewerState.open} onClose={closeFileViewerModal}>
        <Box className={styles.jobDescriptionBox}>
          <ModalHeader title={fileViewerState.title} onClose={closeFileViewerModal} />
          {fileViewerState?.uris?.length > 0 ? (
            <FileViewer fileUrls={[{ uri: fileViewerState.uris }]} />
          ) : (
            <Typography textAlign="center">No File Found.</Typography>
          )}
        </Box>
      </Modal>
    </JobContext.Provider>
  );
}

JobContextProvider.defaultProps = {
  children: null,
};

JobContextProvider.propTypes = {
  children: PropTypes.node,
};
