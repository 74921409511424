import React from 'react';
import { Box } from '@mui/material';
import { LineWave } from 'react-loader-spinner';
import { useSelector } from 'react-redux';

function GlobalLoader() {
  const { primary } = useSelector(state => state.theme.palette);

  return (
    <Box sx={{ height: '100vh' }} className="d-flex align-items-center justify-content-center">
      <LineWave
        height="150"
        width="150"
        color={primary.main}
        ariaLabel="line-wave"
        wrapperStyle={{}}
        wrapperClass=""
        visible
        firstLineColor=""
        middleLineColor=""
        lastLineColor=""
      />
    </Box>
  );
}

export default GlobalLoader;
