export const PENDING = 'pending';
export const IN_PROGRESS = 'in_progress';
export const REJECTED = 'rejected';
export const COMPLETED = 'completed';
export const REVISION_REQUIRED = 'revision_required';
export const SENT = 'sent';
export const HIRED = 'hired';
export const OFFER_PACKET_SENT = 'offer_packet_sent';
export const HIRE_PACKET_VIEWED = 'hire_packet_viewed';
export const INFORMATION_ADDED = 'information_added';
export const INFORMATION_IN_REVISION = 'information_in_revision';
export const INFORMATION_APPROVED = 'information_approved';

export const HIRE_PACKET_SENT = 'hire_packet_sent';
export const EMPLOYEE_INFO_COMPLETED = 'employee_info_completed';
export const ADDITIONAL_TASKS_COMPLETED = 'additional_tasks_completed';

export const ONBOARDING_QUEUE_TABLE_HEAD_CELLS = [
  {
    label: 'Applicant',
    id: 'applicant',
  },
  {
    label: 'Job Title',
    id: 'job_title',
  },
  {
    label: 'Status',
    id: 'status',
  },
  {
    label: 'Type',
    id: 'is_direct_hire',
  },
];

export const ONBOARDING_QUEUE_STATUS_MAP = {
  [PENDING]: 'Pending',
  [IN_PROGRESS]: 'In Progress',
  [HIRED]: 'Hired',
  [OFFER_PACKET_SENT]: 'Hire packet sent',
  [HIRE_PACKET_VIEWED]: 'Hire packet viewed',
  [INFORMATION_ADDED]: 'Information added',
  [INFORMATION_IN_REVISION]: 'Revision required',
  [INFORMATION_APPROVED]: 'Information approved',
  [COMPLETED]: 'Completed',
};

export const ONBOARDING_TIMELINE_STEPS = [
  { label: 'Hire Packet Viewed', value: HIRE_PACKET_VIEWED },
  { label: 'Employee Info', value: EMPLOYEE_INFO_COMPLETED },
  { label: 'Additional Tasks', value: ADDITIONAL_TASKS_COMPLETED },
];

export const ONBOARDING_TASK_STATUSES = {
  [PENDING]: 'Pending',
  [SENT]: 'Sent',
  [REVISION_REQUIRED]: 'Revision required',
  [COMPLETED]: 'Completed',
  [REJECTED]: 'Rejected',
};

export const ONBOARDING_TASK_STATUSES_COLOR_MAP = {
  // Mui color types
  [COMPLETED]: 'success',
  [PENDING]: 'primary',
  [REJECTED]: 'error',
  [SENT]: 'primary',
  [REVISION_REQUIRED]: 'warning',
};

export const PERSONAL_INFO_LABEL_VALUE_MAP = {
  employee_name: 'Employee name',
  gender: 'Gender',
  dob: 'Dob',
  age: 'Age',
  nationality: 'Nationality',
  marital_status: 'Marital status',
  national_id: 'National id',
  personal_mob: 'Personal mob',
  personal_email: 'Personal email',
  address: 'Address',
};

export const BANK_INFO_LABEL_VALUE_MAP = {
  account_number: 'Account number',
  account_name: 'Account name',
  bank_name: 'Bank name',
  bank_address: 'Bank address',
  iban: 'IBAN',
  currency: 'Currency',
};
