import store from 'store';
import { border, disabled } from 'styles/common/colors';

const { primary } = store.getState().theme.palette;

const selectContainerCommonStyles = {
  border: `1px solid ${border}`,
  borderRadius: '4px',
  color: '#232329',
  background: 'white',
  width: '100%',
  fontSize: '12px',
  transition: 'border 0.3s',

  ':hover': {
    borderColor: primary.light,
  },

  ':focus': {
    borderColor: primary.light,
    outline: 'none',
  },

  ':disabled': {
    background: disabled,
  },
};

const commonControlStyles = {
  height: '38px',
  border: 'none',
  boxShadow: 'none',

  ':disabled': {
    background: disabled,
  },
};

const multiValueCommonStyles = { backgroundColor: 'white', border: `1px solid ${primary.lighter}` };

const commonMultiSelectControlStyles = { borderRadius: '0px', border: 'none', boxShadow: 'none' };

export const getFieldOptionStyles = (base, state) => {
  const bg = (() => {
    if (state?.isSelected) {
      return primary.main;
    }

    if (state?.isFocused) {
      return primary.lighter;
    }
    return 'white';
  })();

  return {
    ...base,
    backgroundColor: bg,
    fontSize: '12px',
    ':active': {
      backgroundColor: primary.lighter,
    },
  };
};

export const getFormikSelectControlStyles = base => ({
  ...base,
  ...commonControlStyles,
});

export const getFormikCreatableSelectControlStyles = base => ({
  ...base,
  ...commonControlStyles,

  ':hover': {
    borderColor: primary.light,
  },

  ':focus': {
    border: `1px solid ${primary.light}`,
    outline: 'none',
  },
});

export const getFormikSelectContainerStyles = base => ({
  ...base,
  ...selectContainerCommonStyles,
});

export const getFormikCreatableSelectContainerStyles = base => ({
  ...base,
  ...selectContainerCommonStyles,
});

export const getFormikMultiSelectContainerStyles = base => ({
  ...base,
  ...selectContainerCommonStyles,
});

export const getFormikCreatableMultiSelectContainerStyles = base => ({
  ...base,
  ...selectContainerCommonStyles,
});

export const getFormikMultiSelectControlStyles = base => ({
  ...base,
  ...commonMultiSelectControlStyles,
});

export const getFormikCreatableMultiSelectControlStyles = base => ({
  ...base,
  ...commonMultiSelectControlStyles,
});

export const getFormikMultiSelectMultiValStyles = base => ({
  ...base,
  ...multiValueCommonStyles,
});

export const getFormikCreatableMultiSelectMultiValStyles = base => ({
  ...base,
  ...multiValueCommonStyles,
});

export const getFormikMultiSelectMultiValRemoveStyles = base => ({
  ...base,
  backgroundColor: disabled,
});

export const formikMultiSelectStyles = {
  menuPortal: base => ({ ...base, zIndex: 1700 }),
  container: getFormikMultiSelectContainerStyles,
  control: getFormikMultiSelectControlStyles,
  multiValue: getFormikMultiSelectMultiValStyles,
  multiValueRemove: getFormikMultiSelectMultiValRemoveStyles,
  option: getFieldOptionStyles,
};

export const formikSelectStyles = {
  menuPortal: base => ({ ...base, zIndex: 1700 }),
  option: getFieldOptionStyles,
  container: getFormikSelectContainerStyles,
  control: getFormikSelectControlStyles,
};

export const formikCreatableMultiSelectStyles = {
  menuPortal: base => ({ ...base, zIndex: 1700 }),
  container: getFormikCreatableMultiSelectContainerStyles,
  control: getFormikCreatableMultiSelectControlStyles,
  multiValue: getFormikCreatableMultiSelectMultiValStyles,
  option: getFieldOptionStyles,
  multiValueRemove: base => ({
    ...base,
    backgroundColor: disabled,
  }),
};

export const formikCreatableSelectStyles = {
  menuPortal: base => ({ ...base, zIndex: 1700 }),
  option: getFieldOptionStyles,
  container: getFormikCreatableSelectContainerStyles,
  control: getFormikCreatableSelectControlStyles,
};
