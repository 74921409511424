export const ONBOARD_FORM_STEPS = ['Personal Info', 'Education', 'Work Experience'];

export const ADD_EMPLOYEE_INFO_STEPS = [
  { label: 'Personal Info', value: 'personal_info' },
  { label: 'Background', value: 'education_and_experience' },
  { label: 'Additional Tasks', value: 'additional_tasks' },
  { label: 'Congrats', value: 'congrats', hideFromTimeline: true },
];

export const ADD_EMPLOYEE_INFO_STEPS_WITHOUT_TASK = [
  { label: 'Personal Info', value: 'personal_info' },
  { label: 'Background', value: 'education_and_experience' },
  { label: 'Congrats', value: 'congrats', hideFromTimeline: true },
];
