import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { pick } from 'lodash';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Api hooks
import {
  useDeleteHirePacketTaskAttachmentsMutation,
  useGetHirePacketDetailQuery,
  useGetOnboardingEmployeeEducationAndExperienceQuery,
  useGetOnboardingEmployeeProfileQuery,
  useGetOnboardingQueueItemDetailQuery,
  useUpdateHirePacketTaskAttachmentsMutation,
  useUpdateOnboardingEmployeeEducationAndExperienceMutation,
  useUpdateOnboardingEmployeeProfileMutation,
} from 'services/private/onboarding';

// components & utilities
import SectionLoader from 'containers/common/loaders/SectionLoader';
import {
  INFORMATION_ADDED,
  INFORMATION_APPROVED,
} from 'containers/portal/hr/onboarding/onboarding-queue/utilities/data';
import { ADD_EMPLOYEE_INFO_STEPS, ADD_EMPLOYEE_INFO_STEPS_WITHOUT_TASK } from '../utilities/data';
import {
  EDUCATION_FIELD_ARRAY_DEF_VAL,
  EXPERIENCE_AND_EDUCATION_INIT_VALS,
  EXPERIENCE_FIELD_ARRAY_DEF_VAL,
  PERSONAL_INFO_INIT_VALS,
} from '../utilities/formUtils';

const initFormValues = {
  personalInfo: PERSONAL_INFO_INIT_VALS,
  educationAndExperience: EXPERIENCE_AND_EDUCATION_INIT_VALS,
  additionalTasks: { files: [] },
};

export const AddInfoContext = createContext({
  activeStep: 0,
  goPrevious: () => {},
  goNext: () => {},
  jumpToStep: () => {},
  timelineSteps: ADD_EMPLOYEE_INFO_STEPS.filter(s => !s.hideFromTimeline),
  hasAdditionalTasks: false,
  formValues: initFormValues,
  formsDisabled: false,
  onboarding: null,
  taskFilesToRemove: [],
  setTaskFilesToRemove: () => {
    /** @param {attachmentId:Number} required */
  },
  submitForms: async () => {
    /** @param {formData:Object} optional */
  },
  updateFormValues: () => {
    /** @param {formKey:String} required */
    /** @param {values:Object} required */
  },
});

function AddInfoContextProvider({ children }) {
  const [taskFilesToRemove, setTaskFilesToRemove] = useState([]);
  const [deleteTaskAttachment] = useDeleteHirePacketTaskAttachmentsMutation();

  const [updateTaskAttachments] = useUpdateHirePacketTaskAttachmentsMutation();
  const [updateEducationAndExperience] = useUpdateOnboardingEmployeeEducationAndExperienceMutation();
  const [updatePersonalInfo] = useUpdateOnboardingEmployeeProfileMutation();
  const { profile } = useSelector(state => state?.auth?.user);
  const [activeStep, setActiveStep] = useState(0);
  const [formValues, setFormValues] = useState(initFormValues);

  const { data: hirePacket, isLoading } = useGetHirePacketDetailQuery(profile?.offer_packet, {
    skip: !profile?.offer_packet,
  });

  const { data: onboarding, isLoading: loadingOnboarding } = useGetOnboardingQueueItemDetailQuery(
    hirePacket?.onboarding_queue_id,
    {
      skip: !hirePacket?.onboarding_queue_id,
    }
  );

  const tasks = hirePacket?.task || [];

  const { data: profileData } = useGetOnboardingEmployeeProfileQuery(profile?.id);
  const { data: historyData } = useGetOnboardingEmployeeEducationAndExperienceQuery(profile?.id);

  const updateFormValues = useCallback(
    (formKey, values) => {
      if (formKey in initFormValues) {
        setFormValues(prev => ({ ...prev, [formKey]: values }));
      }
    },
    [initFormValues]
  );

  useEffect(() => {
    if (profileData) {
      const picked = pick(profileData, Object.keys(PERSONAL_INFO_INIT_VALS));
      updateFormValues('personalInfo', picked);
    }
  }, [profileData]);

  useEffect(() => {
    if (historyData) {
      updateFormValues('educationAndExperience', {
        education:
          historyData?.education?.length === 0 ? [EDUCATION_FIELD_ARRAY_DEF_VAL] : historyData?.education,
        experience:
          historyData?.experience?.length === 0 ? [EXPERIENCE_FIELD_ARRAY_DEF_VAL] : historyData?.experience,
      });
    }
  }, [historyData]);

  const formsDisabled = useMemo(() => {
    if (onboarding?.status === INFORMATION_APPROVED) {
      return true;
    }

    if (onboarding?.status === INFORMATION_ADDED) {
      return true;
    }

    return false;
  }, [onboarding]);

  const hasAdditionalTasks = tasks.length !== 0;

  const filteredInfoSteps = useMemo(() => {
    if (!hasAdditionalTasks) return ADD_EMPLOYEE_INFO_STEPS_WITHOUT_TASK;

    return ADD_EMPLOYEE_INFO_STEPS;
  }, [hasAdditionalTasks]);

  const timelineSteps = useMemo(() => {
    if (formsDisabled) {
      return filteredInfoSteps.filter(s => !s.hideFromTimeline).map(item => ({ ...item, completed: true }));
    }

    return filteredInfoSteps.filter(s => !s.hideFromTimeline);
  }, [filteredInfoSteps, formsDisabled]);

  const filteredTimelineSteps = useMemo(() => {
    if (!hasAdditionalTasks) {
      return timelineSteps.filter(step => step.value !== 'additional_tasks');
    }
    return timelineSteps;
  }, [hasAdditionalTasks]);

  const goPrevious = useCallback(() => {
    if (activeStep === 0) return;

    setActiveStep(prev => prev - 1);
  }, [activeStep]);

  const goNext = useCallback(() => {
    if (activeStep >= filteredInfoSteps.length) return;

    setActiveStep(prev => prev + 1);
  }, [activeStep, filteredInfoSteps]);

  const jumpToStep = useCallback(index => {
    setActiveStep(index);
  }, []);

  const submitForms = useCallback(
    async formsData => {
      const payload = formsData ? { ...formValues, ...formsData } : formValues;
      const { additionalTasks, educationAndExperience, personalInfo } = payload;

      await updatePersonalInfo({ id: profile?.id, ...personalInfo });

      await updateEducationAndExperience({
        id: profile?.id,
        education: educationAndExperience.education,
        experience: educationAndExperience.experience,
      });

      for (let index = 0; index < additionalTasks.files.length; index += 1) {
        const item = additionalTasks.files[index];
        // eslint-disable-next-line no-await-in-loop
        await updateTaskAttachments({
          id: item?.taskId,
          files: [item?.file],
        });
      }

      for (let index = 0; index < taskFilesToRemove.length; index += 1) {
        // eslint-disable-next-line no-await-in-loop
        await deleteTaskAttachment(taskFilesToRemove[index]);
      }
      // reset
      updateFormValues('additionalTasks', {
        files: [],
      });
    },
    [formValues, taskFilesToRemove]
  );

  const ctxValue = useMemo(
    () => ({
      timelineSteps: formsDisabled
        ? filteredTimelineSteps.map(item => ({ ...item, completed: true }))
        : filteredTimelineSteps,
      goNext,
      goPrevious,
      jumpToStep,
      activeStep,
      hasAdditionalTasks,
      formValues,
      updateFormValues,
      submitForms,
      formsDisabled,
      onboarding,
      taskFilesToRemove,
      setTaskFilesToRemove,
    }),
    [
      timelineSteps,
      filteredTimelineSteps,
      updateFormValues,
      goNext,
      goPrevious,
      jumpToStep,
      activeStep,
      hasAdditionalTasks,
      formValues,
      submitForms,
      formsDisabled,
      onboarding,
      taskFilesToRemove,
    ]
  );

  return (
    <AddInfoContext.Provider value={ctxValue}>
      {isLoading || loadingOnboarding ? <SectionLoader /> : children}
    </AddInfoContext.Provider>
  );
}

AddInfoContextProvider.propTypes = {
  children: PropTypes.node,
};

AddInfoContextProvider.defaultProps = {
  children: null,
};

export default AddInfoContextProvider;
