import React, { createContext, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  filterFormInitialValues,
  myLeavesTabFilterKey,
  leavesListTabFilterKey,
  recentRequestsFilterKey,
} from '../utilities/data';

const initialValues = {
  getFilters: () => {},
  getAppliedFiltersCount: () => {},
  updateFilters: () => {},
  clearFilters: () => {},
};

export const RequestsContext = createContext(initialValues);

function RequestsContextProvider({ children }) {
  const [filters, setFilters] = useState({
    [myLeavesTabFilterKey]: filterFormInitialValues,
    [leavesListTabFilterKey]: filterFormInitialValues,
    [recentRequestsFilterKey]: filterFormInitialValues,
  });

  const updateFilters = useCallback(
    (key, value) => setFilters(prev => ({ ...prev, [key]: { ...prev[key], ...value } })),
    []
  );

  const clearFilters = useCallback(
    key => setFilters(prev => ({ ...prev, [key]: filterFormInitialValues })),
    []
  );

  const getFilters = useCallback(
    key => filters[key],
    [filters]
  );

  const getAppliedFiltersCount = useCallback(
    key => {
      const target = filters[key];
      return Object.keys(target).filter(k => target[k] && k !== 'employeeName' && k !== 'leave_status').length;
    },
    [filters]
  );

  const contextValue = useMemo(
    () => ({ getFilters, updateFilters, clearFilters, getAppliedFiltersCount }),
    [getFilters, getAppliedFiltersCount, updateFilters, clearFilters]
  );

  return <RequestsContext.Provider value={contextValue}>{children}</RequestsContext.Provider>;
}

RequestsContextProvider.defaultProps = {
  children: null,
};

RequestsContextProvider.propTypes = {
  children: PropTypes.node,
};

export default RequestsContextProvider;
