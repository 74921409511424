import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import SubmitBtn from 'containers/common/components/SubmitBtn';
import { Form, Formik } from 'formik';
import propTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';

// API & HOOKS
import { useGetAttendanceDatesQuery, useUpdateDailyReportsMutation } from 'services/private/users';
import { useGetAssignedProjectsLookupQuery } from 'services/private/lookups';

// COMPONENTS & UTILS
import FormikField from 'shared/components/form/FormikField';
import FormikSelect from 'shared/components/form/FormikSelect';
import { defaultConfig } from 'shared/components/ckEditor/config';
import FormikTextEditor from 'shared/components/form/FormikTextEditor';
import { dailyReportFormInitialValue, dailyReportFormInitialSchema } from '../utilities/formUtils';

function EditDailyReportForm({ responseHandler, afterRequestSend, formData }) {
  const { enqueueSnackbar } = useSnackbar();
  const { data: projectsData } = useGetAssignedProjectsLookupQuery();
  const { data: attendanceData } = useGetAttendanceDatesQuery();
  const [initValues, setInitValues] = useState(dailyReportFormInitialValue);
  const [updateDailyReport] = useUpdateDailyReportsMutation();

  const modifiedOptions = useMemo(() => {
    if (projectsData) {
      const options = projectsData?.map(opt => ({
        label: opt?.project_name,
        value: opt?.id,
      }));
      return options;
    }
    return [];
  }, [projectsData]);

  useEffect(() => {
    setInitValues({
      project: formData.project,
      productive_hours: formData.productive_hours,
      mins: formData.mins,
      report: formData.report,
      checked_in_date: formData.attendance,
    });
  }, [formData]);

  const modifiedDate = useMemo(() => {
    if (attendanceData) {
      const options = attendanceData?.map(opt => ({
        label: opt?.date,
        value: opt?.id,
      }));
      return options;
    }

    return [];
  }, [attendanceData]);

  return (
    <Box>
      <Formik
        enableReinitialize
        initialValues={initValues}
        validationSchema={dailyReportFormInitialSchema}
        onSubmit={async (values, { resetForm }) => {
          const response = await updateDailyReport({
            productive_hours: values.productive_hours || 0,
            mins: values.mins || 0,
            project: values.project,
            report: values.report,
            attendance: formData.attendance,
            id: formData.id,
          });
          responseHandler(response);
          if (!response.error) {
            enqueueSnackbar('Daily Report updated successfully!', { variant: 'success' });
            resetForm();
          } else {
            enqueueSnackbar(response?.error?.data?.message, { variant: 'error' });
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form className="d-flex align-items-start flex-column gap-3">
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Typography className="required" variant="label">
                  Checked In Date
                </Typography>
                <FormikSelect name="checked_in_date" placeholder="Select Date" options={modifiedDate} />
              </Grid>

              <Grid sx={{ zIndex: 50 }} item xs={12} md={6}>
                <Typography className="required" variant="label">
                  Project
                </Typography>
                <FormikSelect name="project" placeholder="Select Project" options={modifiedOptions} />
              </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={4}>
              <Grid item xs={12} md={6}>
                <Typography variant="label">Hours</Typography>
                <FormikField placeholder="Hours" type="number" name="productive_hours" />
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography className="required" variant="label">
                  Minutes
                </Typography>
                <FormikField placeholder="Minutes" type="number" name="mins" />
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                <Typography className="required" variant="label">
                  Report
                </Typography>
              </Grid>

              <Grid item xs={12} md={12}>
                <FormikTextEditor
                  name="report"
                  config={defaultConfig}
                />
              </Grid>
            </Grid>

            <Stack direction="row" mt={2} spacing={1}>
              <SubmitBtn label="Update" isLoading={isSubmitting} />
              <Button variant="contained" color="secondary" onClick={afterRequestSend}>
                Cancel
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

EditDailyReportForm.defaultProps = {
  afterRequestSend: () => null,
  responseHandler: () => {},
  formData: null,
};

EditDailyReportForm.propTypes = {
  afterRequestSend: propTypes.func,
  responseHandler: propTypes.func,
  formData: propTypes.object,
};

export default EditDailyReportForm;
